:root {
  /* Colors */
  --black: #000;
  --white: #fff;
  --md-primary-color: #EB0000;
  --md-secondary-color: #eb7e23;
  --lt-gray-2: #eee;
  --lt-gray: #F5F5F5;
  --md-gray: #777777;
  --dk-gray: #212529;
  --error: #ca1717;
  --max-width: 1380px;
  --max-width-large: 1500px;
  --large-container-padding: 40px;
  --small-container-padding: 20px;
  /* Margin top and bottom on block element items */
  --large-block-margin: 80px;
  --small-block-margin: 30px;
  /* Margin on position-right and left floated items */
  --large-position-margin: 35px;
}

.sg-element, .styleguide hr, .sg-colors {
  zoom: 1;
}
.sg-element::before, .styleguide hr::before, .sg-colors::before, .sg-element::after, .styleguide hr::after, .sg-colors::after {
  content: "";
  display: table;
}
.sg-element::after, .styleguide hr::after, .sg-colors::after {
  clear: both;
}

.block-related-work .content .block-callout-link::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.styleguide hr, .fui-progress-end .fui-progress, .block-form {
  margin-block: var(--small-block-margin);
}
@media (width >= 750px) {
  .styleguide hr, .fui-progress-end .fui-progress, .block-form {
    margin-block: var(--large-block-margin);
  }
}

.block-accordion {
  box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.22);
  margin-block: 45px;
  padding: 0;
}
.block-accordion + .block-accordion {
  margin-block-start: -23px;
}
.block-accordion .accordion-toggle {
  display: block;
  width: 100%;
  margin-block-end: 0;
}
.block-accordion .accordion-toggle button {
  background-color: var(--lt-gray);
  border: 0;
  color: var(--black);
  display: block;
  cursor: pointer;
  padding-block: 37px;
  padding-inline-start: 30px;
  padding-inline-end: 70px;
  position: relative;
  text-align: start;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  width: 100%;
}
@media (width <= 499px) {
  .block-accordion .accordion-toggle button {
    font-size: 1rem;
  }
}
@media (width >= 500px) and (width <= 749px) {
  .block-accordion .accordion-toggle button {
    font-size: 1.15625rem;
  }
}
@media (width >= 750px) and (width <= 1023px) {
  .block-accordion .accordion-toggle button {
    font-size: 1.3125rem;
  }
}
@media (width >= 1024px) and (width <= 1249px) {
  .block-accordion .accordion-toggle button {
    font-size: 1.46875rem;
  }
}
@media (width >= 1250px) {
  .block-accordion .accordion-toggle button {
    font-size: 1.625rem;
  }
}
@media (width >= 1024px) {
  .block-accordion .accordion-toggle button {
    padding-inline-start: 70px;
  }
}
.block-accordion .accordion-toggle button::before {
  content: "";
  background-color: var(--md-primary-color);
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2037%2037%22%3E%3Cpath%20style%3D%22fill%3A%20%23fff%3B%22%20d%3D%22M18.5%2C30.18c-.83%2C0-1.5-.67-1.5-1.5V9.4c0-.83.67-1.5%2C1.5-1.5s1.5.67%2C1.5%2C1.5v19.28c0%2C.83-.67%2C1.5-1.5%2C1.5Z%22%2F%3E%3Cpath%20style%3D%22fill%3A%20%23fff%3B%22%20d%3D%22M29%2C20.54H8c-.83%2C0-1.5-.67-1.5-1.5s.67-1.5%2C1.5-1.5h21c.83%2C0%2C1.5.67%2C1.5%2C1.5s-.67%2C1.5-1.5%2C1.5Z%22%2F%3E%3C%2Fsvg%3E");
  border-radius: 9999px;
  display: block;
  width: 37px;
  height: 37px;
  text-align: center;
  position: absolute;
  inset-inline-end: 27px;
  inset-block-start: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.block-accordion .accordion-toggle button[aria-expanded=true] {
  color: var(--dk-gray);
  background: var(--white);
  border-block-end: 0;
}
.block-accordion .accordion-toggle button:hover, .block-accordion .accordion-toggle button:focus {
  background-color: var(--white);
  color: var(--dk-gray);
}
.block-accordion .accordion-toggle button:hover::before, .block-accordion .accordion-toggle button:focus::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2037%2037%22%3E%3Cpath%20style%3D%22fill%3A%20%23fff%3B%22%20d%3D%22M29%2C20.54H8c-.83%2C0-1.5-.67-1.5-1.5s.67-1.5%2C1.5-1.5h21c.83%2C0%2C1.5.67%2C1.5%2C1.5s-.67%2C1.5-1.5%2C1.5Z%22%2F%3E%3C%2Fsvg%3E");
}
.block-accordion .accordion-toggle button:focus {
  outline: none;
}
.block-accordion .accordion-toggle button:focus-visible {
  outline: 2px solid var(--md-primary-color);
  outline-offset: 4px;
}
.block-accordion .accordion-content {
  background-color: var(--white);
  padding-inline: 30px;
  padding-block: 0 53px;
}
@media (width <= 499px) {
  .block-accordion .accordion-content {
    font-size: 0.875rem;
  }
}
@media (width >= 500px) and (width <= 749px) {
  .block-accordion .accordion-content {
    font-size: 0.90625rem;
  }
}
@media (width >= 750px) and (width <= 1023px) {
  .block-accordion .accordion-content {
    font-size: 0.9375rem;
  }
}
@media (width >= 1024px) and (width <= 1249px) {
  .block-accordion .accordion-content {
    font-size: 0.96875rem;
  }
}
@media (width >= 1250px) {
  .block-accordion .accordion-content {
    font-size: 1rem;
  }
}
@media (width >= 1024px) {
  .block-accordion .accordion-content {
    padding-inline: 70px;
  }
}
.block-accordion .accordion-content *:first-child {
  margin-block-start: 0;
}
.block-accordion .accordion-content *:last-child {
  margin-block-end: 0;
}

.block-image-tiles-callout {
  display: grid;
  gap: var(--small-block-margin);
}
@media (width >= 768px) {
  .block-image-tiles-callout {
    gap: 40px;
    grid-template-columns: 1fr minmax(0, 415px);
  }
}
@media (width > 1024px) {
  .block-image-tiles-callout {
    gap: 100px;
  }
}
@media (width >= 768px) {
  .block-image-tiles-callout.image-position-right {
    grid-template-columns: minmax(0, 415px) 1fr;
  }
}
@media (width >= 768px) {
  .block-image-tiles-callout.image-position-right .media {
    grid-column: 2;
    grid-row: 1;
  }
}
@media (width >= 768px) {
  .block-image-tiles-callout.image-position-right .content {
    grid-column: 1;
    grid-row: 1;
  }
}
.block-image-tiles-callout .media {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.block-image-tiles-callout .media picture {
  height: 100%;
  width: 100%;
}
.block-image-tiles-callout .media picture:first-of-type {
  grid-column: 1;
  grid-row: 1/3;
}
.block-image-tiles-callout .media picture:nth-of-type(2) {
  grid-column: 2;
  grid-row: 1;
}
.block-image-tiles-callout .media picture:nth-of-type(3) {
  grid-column: 2;
  grid-row: 2;
}
.block-image-tiles-callout .media img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.block-image-tiles-callout .content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.block-image-tiles-callout .btn:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.block-image-tiles-callout .btn:focus-visible {
  outline: 2px solid var(--md-primary-color);
  outline-offset: 4px;
}

.block-related-work p {
  margin-block: 0;
  text-transform: uppercase;
}
.block-related-work .heading {
  margin-block: 0 32px;
}
.block-related-work .swiper-slide {
  padding-block-end: 90px;
}
.block-related-work .swiper-pagination {
  inset-inline-start: 50% !important;
  translate: -50% 0 !important;
  width: calc(100% - 280px) !important;
}
.block-related-work .swiper-button-prev {
  inset-inline-start: auto;
  inset-inline-end: 78px;
}
.block-related-work .swiper-button {
  inset-block-start: auto;
  inset-block-end: 6px;
  translate: 0;
}
.block-related-work figure {
  margin: 0;
}
.block-related-work .content {
  background-color: var(--white);
  box-shadow: 4.8px 4.8px 16px 0px rgba(0, 0, 0, 0.3);
  margin-inline: 22px;
  margin-block-start: -56px;
  padding: 30px 20px;
  position: relative;
  text-align: center;
  z-index: 1;
}
.block-related-work .content p {
  margin-block-end: 0;
}
.block-related-work .content h3 {
  margin-block-start: 0;
}
.block-related-work .content .block-callout-link {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2218%22%20viewBox%3D%220%200%2016%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.586304%208.59881H14%22%20stroke%3D%22%23EB0000%22%20stroke-width%3D%222.5%22%2F%3E%3Cpath%20d%3D%22M6.60425%201.4126C6.60425%201.4126%208.04443%202.81205%2010.9248%205.61096C11.8129%206.47399%2014%208.5992%2014%208.5992L6.60425%2016.2557%22%20stroke%3D%22%23EB0000%22%20stroke-width%3D%222.5%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  padding-inline-end: 24px;
}
.block-related-work .content .block-callout-link:focus-visible {
  outline: 2px solid var(--md-primary-color);
  outline-offset: 4px;
}

.block-rich-content-overlapping {
  display: grid;
  gap: 40px;
}
@media (width >= 768px) {
  .block-rich-content-overlapping {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.block-rich-content-overlapping.dark-bg .content-inner {
  background-color: var(--md-primary-color);
}
.block-rich-content-overlapping.dark-bg .content-inner,
.block-rich-content-overlapping.dark-bg a:not(.btn-primary) {
  color: var(--white);
}
.block-rich-content-overlapping.dark-bg .btn-primary {
  background-color: var(--white);
  color: var(--md-primary-color);
}
.block-rich-content-overlapping.dark-bg .btn-primary:hover {
  background-color: var(--lt-gray);
}
.block-rich-content-overlapping.dark-bg li:before {
  color: var(--white);
}
@media (width > 768px) {
  .block-rich-content-overlapping.image-position-left .media {
    grid-column: 1/3;
    grid-row: 1;
  }
}
@media (width > 768px) {
  .block-rich-content-overlapping.image-position-left .content {
    grid-column: 2/4;
    grid-row: 1;
  }
}
@media (width > 768px) {
  .block-rich-content-overlapping .media {
    grid-column: 2/4;
    grid-row: 1;
  }
}
@media (width > 768px) {
  .block-rich-content-overlapping .content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    grid-column: 1/3;
    grid-row: 1;
    justify-content: center;
  }
}
.block-rich-content-overlapping .content a:not(.btn-primary) {
  text-decoration: underline;
  text-transform: uppercase;
}
.block-rich-content-overlapping .content .btn-primary {
  margin-block-start: 40px;
}
@media (width > 768px) {
  .block-rich-content-overlapping .content ul,
  .block-rich-content-overlapping .content ol {
    columns: 2;
  }
}
.block-rich-content-overlapping .content-inner {
  background-color: var(--lt-gray);
  box-shadow: 4.8px 4.8px 16px 0px rgba(0, 0, 0, 0.3);
  padding: 30px;
}
@media (width > 768px) {
  .block-rich-content-overlapping .content-inner {
    padding: 65px 100px 50px;
  }
}

.block-testimonial-gallery.swiper .swiper-slide {
  padding-block-end: 80px;
}
@media (width >= 768px) {
  .block-testimonial-gallery.swiper .swiper-slide {
    padding-block: 6px 40px;
    padding-inline: 84px 120px;
  }
}
.block-testimonial-gallery.swiper .swiper-button {
  inset-block-start: auto;
  inset-block-end: 6px;
}
@media (width >= 768px) {
  .block-testimonial-gallery.swiper .swiper-button {
    inset-block-start: calc(50% - 20px);
    inset-block-end: auto;
  }
}
@media (width >= 768px) {
  .block-testimonial-gallery.swiper .swiper-button-prev {
    inset-inline-start: 6px;
    inset-inline-end: auto;
  }
}
@media (width >= 768px) {
  .block-testimonial-gallery.swiper .swiper-slide-active + .swiper-slide-next {
    margin-inline-start: -268px;
  }
}
.block-testimonial-gallery .testimonial-quote {
  box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.22);
  padding: 30px;
}
@media (width >= 768px) {
  .block-testimonial-gallery .testimonial-quote {
    padding: 100px 70px;
  }
}
@media (width <= 499px) {
  .block-testimonial-gallery .testimonial-quote p {
    font-size: 1rem;
  }
}
@media (width >= 500px) and (width <= 749px) {
  .block-testimonial-gallery .testimonial-quote p {
    font-size: 1.15625rem;
  }
}
@media (width >= 750px) and (width <= 1023px) {
  .block-testimonial-gallery .testimonial-quote p {
    font-size: 1.3125rem;
  }
}
@media (width >= 1024px) and (width <= 1249px) {
  .block-testimonial-gallery .testimonial-quote p {
    font-size: 1.46875rem;
  }
}
@media (width >= 1250px) {
  .block-testimonial-gallery .testimonial-quote p {
    font-size: 1.625rem;
  }
}
.block-testimonial-gallery .testimonial-quote cite {
  font-style: normal;
  text-transform: uppercase;
}
@media (width <= 499px) {
  .block-testimonial-gallery .testimonial-quote cite {
    font-size: 0.75rem;
  }
}
@media (width >= 500px) and (width <= 749px) {
  .block-testimonial-gallery .testimonial-quote cite {
    font-size: 0.78125rem;
  }
}
@media (width >= 750px) and (width <= 1023px) {
  .block-testimonial-gallery .testimonial-quote cite {
    font-size: 0.8125rem;
  }
}
@media (width >= 1024px) and (width <= 1249px) {
  .block-testimonial-gallery .testimonial-quote cite {
    font-size: 0.84375rem;
  }
}
@media (width >= 1250px) {
  .block-testimonial-gallery .testimonial-quote cite {
    font-size: 0.875rem;
  }
}
.block-testimonial-gallery .swiper-slide-active .testimonial-quote {
  background-color: var(--md-primary-color);
  color: var(--white);
}

.row {
  display: block;
  justify-content: space-between;
  align-items: flex-end;
  margin-inline: -10px;
}
@media (width >= 1024px) {
  .row {
    display: flex;
  }
}

.column {
  flex: 1 1 auto;
  padding-block: 8px;
  margin-inline: 10px;
  box-sizing: border-box;
}
@media (width >= 750px) {
  .column {
    padding-block: 15px;
  }
}

.fui-i {
  --fui-label-font-size: 1rem;
  --fui-check-font-size: 1rem;
  --fui-input-font-size: 1rem;
  --fui-instructions-font-size: 1rem;
  --fui-error-font-size: 1rem;
  --fui-tabs-font-size: 1rem;
  --fui-btn-font-size: 1rem;
  --fui-progress-font-size: 1rem;
  --fui-table-th-font-size: 1rem;
  --fui-tabs-margin-bottom: 30px;
  --fui-progress-height: 30px;
  --fui-required-color: var(--md-primary-color);
  --fui-font: var(--font-family-sans-serif);
  --fui-color: var(--black);
  --fui-label-color: var(--black);
  --fui-primary-color: var(--md-primary-color);
  --fui-primary-color-hover: var(--md-primary-color);
  --fui-border-radius: 0.25rem;
  --fui-border-color: var(--black);
  --fui-focus-border-color: var(--black);
  --fui-focus-shadow: 0 0 0 3px rgba(0, 0, 0, #000);
  --fui-check-label-bg-color: var(--white);
  --fui-check-bg-color: var(--white);
  --fui-check-check-border-radius: 2px;
  --fui-check-label-width: 20px;
  --fui-check-label-height: 20px;
  --fui-check-bg-color-checked: var(--md-primary-color);
  --fui-input-background-color: var(--white);
  --fui-input-error-color: var(--error);
  --fui-input-error-border-color: var(--error);
  --fui-table-th-color: var(--white);
  --fui-progress-color: var(--white);
  --fui-tab-active-bg-color: var(--md-primary-color);
  --fui-font-size: 1rem;
  --fui-error: var(--error);
  --fui-success: var(--md-secondary-color);
  --fui-row-gutter: 30px;
  --fui-row-gutter-top: 20px;
  --fui-loading-min-height: 20px;
  --fui-loading-height: 20px;
  --fui-loading-width: 20px;
  --fui-loading-margin-top: -10px;
  --fui-loading-margin-left: -10px;
  --fui-loading-border-width: 2px;
  --fui-alert-padding: 20px;
  --fui-alert-font-size: 1rem;
  --fui-alert-font-weight: 500;
  --fui-alert-margin-bottom: 30px;
  --fui-btn-font-size: 1rem;
  --fui-btn-padding: 10px 15px;
  --fui-title-margin: 0 0 20px;
  --fui-title-font-size: 2.4rem;
  --fui-title-font-weight: 700;
  --fui-page-title-margin: 0 0 20px;
  --fui-page-title-font-size: 2.2rem;
  --fui-page-title-font-weight: 600;
  --fui-tabs-margin-bottom: 20px;
  --fui-tabs-font-size: 1rem;
  --fui-tab-padding: 10px 20px;
  --fui-progress-font-size: 1rem;
  --fui-progress-bar-bg-color: var(--md-primary-color);
  --fui-error-font-size: 1rem;
  --fui-error-margin-top: 5px;
  --fui-label-line-height: 1.25;
  --fui-label-font-weight: 500;
  --fui-label-margin: 5px;
  --fui-label-error-color: var(--error);
  --fui-label-error-border-color: var(--error);
  --fui-instructions-font-size: 1rem;
  --fui-instructions-line-height: 1.5;
  --fui-instructions-margin: 8px;
  --fui-instructions-color: var(--black);
  --fui-check-font-size: 1rem;
  --fui-check-line-height: 1.5;
  --fui-check-margin-bottom: 3px;
  --fui-check-margin-right: 30px;
  --fui-check-label-padding-left: 30px;
  --fui-check-label-line-height: 2;
  --fui-check-label-top: 5px;
  --fui-check-check-bg-size: 8px auto;
  --fui-check-radio-border-radius: 50%;
  --fui-check-radio-bg-size: 8px auto;
  --fui-group-padding: 25px;
  --fui-input-font-size: 1rem;
  --fui-input-padding: 10px 20px;
  --fui-repeater-row-padding: 20px;
  --fui-repeater-row-margin-bottom: 20px;
  --fui-table-margin-bottom: 20px;
  --fui-table-row-padding: 15px;
  --fui-table-th-font-size: 1rem;
  --fui-signature-height: 100px;
  --fui-summary-padding: 20px;
  --fui-submit-btn-bg-color: var(--md-primary-color);
  --fui-submit-btn-bg-color-hover: var(--white);
  --fui-submit-btn-border-color: var(--md-primary-color);
  --fui-submit-btn-border-color-hover: var(--md-primary-color);
  --fui-submit-btn-color-hover: var(--md-primary-color);
}

.fui-table th:first-child,
.fui-table td:first-child {
  padding-inline-start: 15px;
}

.fui-row {
  flex-flow: column;
}
@media (width >= 750px) {
  .fui-row {
    flex-flow: row;
  }
}

.fui-legend {
  font-size: 1rem;
}

.fui-label,
.fui-legend {
  margin-block-end: 10px;
  display: block;
  font-weight: 700;
  color: var(--md-primary-color);
}
@media (width <= 499px) {
  .fui-label,
  .fui-legend {
    font-size: 0.9375rem;
  }
}
@media (width >= 500px) and (width <= 749px) {
  .fui-label,
  .fui-legend {
    font-size: 0.9375rem;
  }
}
@media (width >= 750px) and (width <= 1023px) {
  .fui-label,
  .fui-legend {
    font-size: 0.9375rem;
  }
}
@media (width >= 1024px) and (width <= 1249px) {
  .fui-label,
  .fui-legend {
    font-size: 0.9375rem;
  }
}
@media (width >= 1250px) {
  .fui-label,
  .fui-legend {
    font-size: 0.9375rem;
  }
}

.fui-input,
.fui-select,
.fui-textarea {
  border: 1px solid var(--md-lt-gray);
  line-height: 32px;
  padding-block: 8px;
  padding-inline: 15px;
  background: var(--white);
  appearance: none;
  border-radius: 15px;
  color: var(--black);
  width: 100%;
}
@media (width <= 499px) {
  .fui-input,
  .fui-select,
  .fui-textarea {
    font-size: 1rem;
  }
}
@media (width >= 500px) and (width <= 749px) {
  .fui-input,
  .fui-select,
  .fui-textarea {
    font-size: 1.03125rem;
  }
}
@media (width >= 750px) and (width <= 1023px) {
  .fui-input,
  .fui-select,
  .fui-textarea {
    font-size: 1.0625rem;
  }
}
@media (width >= 1024px) and (width <= 1249px) {
  .fui-input,
  .fui-select,
  .fui-textarea {
    font-size: 1.09375rem;
  }
}
@media (width >= 1250px) {
  .fui-input,
  .fui-select,
  .fui-textarea {
    font-size: 1.125rem;
  }
}
.fui-input:focus,
.fui-select:focus,
.fui-textarea:focus {
  box-shadow: none;
  color: var(--black) !important;
  outline: 2px solid var(--md-primary-color) !important;
  outline-offset: 2px;
}

.fui-select:not([multiple], [size]) {
  padding-right: 50px;
  background-position: right 10px center !important;
  background-size: 23px 9px;
  background-repeat: no-repeat;
  text-align: left;
  appearance: none;
  background-image: url("/assets/images/icons/select-arrow.svg") !important;
}

.fui-checkbox,
.fui-radio {
  font-weight: 400;
  color: var(--black);
}
.fui-checkbox label,
.fui-radio label {
  padding-left: 0;
  padding-inline-start: var(--fui-check-label-padding-left);
}
.fui-checkbox label::before,
.fui-radio label::before {
  inset-inline-start: 0;
}

.fui-checkbox input:focus + label::before,
.fui-radio input:focus + label::before {
  outline: 2px solid var(--md-primary-color);
  outline-offset: 2px;
}

.fui-checkbox input:checked + label::before,
.fui-radio input:checked + label::before {
  border: 1px solid var(--md-primary-color);
}

.fui-btn:focus-visible {
  outline-offset: 4px;
  outline: 2px solid var(--md-primary-color);
}

.fui-tab.fui-tab-active a {
  color: var(--white);
}

.fui-progress-bar,
.fui-progress {
  border-radius: 50px;
}

.block-form h2 {
  margin-top: 0;
}
.block-form form {
  margin-top: 2rem;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

.skip-link a,
button.skip-link {
  color: var(--white);
  background: var(--black);
  padding-block: 3px;
  padding-inline: 15px;
  width: auto;
  display: block;
  border-radius: 0 0 5px 5px;
  position: absolute;
  inset-block-start: 0;
  z-index: 4500;
  font-weight: 400;
  text-transform: uppercase;
  inset-inline-start: -10000px;
}
@media (width <= 499px) {
  .skip-link a,
  button.skip-link {
    font-size: 1rem;
  }
}
@media (width >= 500px) and (width <= 749px) {
  .skip-link a,
  button.skip-link {
    font-size: 1rem;
  }
}
@media (width >= 750px) and (width <= 1023px) {
  .skip-link a,
  button.skip-link {
    font-size: 1rem;
  }
}
@media (width >= 1024px) and (width <= 1249px) {
  .skip-link a,
  button.skip-link {
    font-size: 1rem;
  }
}
@media (width >= 1250px) {
  .skip-link a,
  button.skip-link {
    font-size: 1rem;
  }
}
.skip-link a:hover, .skip-link a:focus,
button.skip-link:hover,
button.skip-link:focus {
  text-decoration: none;
  color: var(--white);
  inset-inline-start: 45px;
}

.swiper .swiper-button {
  background-color: var(--md-primary-color);
  border-radius: 9999px;
  color: var(--white);
  height: 56px;
  width: 56px;
}
.swiper .swiper-button:after {
  font-size: 32px;
}
.swiper .swiper-button:focus {
  outline: none;
}
.swiper .swiper-button:focus-visible {
  outline: 2px solid var(--md-primary-color);
  outline-offset: 4px;
}
.swiper .swiper-button-prev {
  inset-inline-start: auto;
  inset-inline-end: 74px;
}
.swiper .swiper-button-next {
  inset-inline-end: 6px;
}
.swiper .swiper-pagination {
  display: none !important;
}
@media (width >= 768px) {
  .swiper .swiper-pagination {
    display: block !important;
  }
}
.swiper .swiper-pagination-bullet {
  background-color: var(--lt-gray-2);
  border-radius: 0;
  height: 4px;
  opacity: 1;
  position: relative;
  transition-property: background-color;
  width: 24px;
}
.swiper .swiper-pagination-bullet:focus {
  outline: none;
}
.swiper .swiper-pagination-bullet:focus-visible {
  outline: 2px solid var(--md-primary-color);
  outline-offset: 4px;
}
.swiper .swiper-pagination-bullet:after {
  content: "";
  height: 24px;
  inset: 50%;
  position: absolute;
  translate: -50% -50%;
  width: 24px;
}
.swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--md-gray);
}

.sg-colors li {
  display: block;
  float: left;
  margin: 10px;
}

.sg-swatch {
  display: block;
  width: 100px;
  height: 100px;
  border: 1px solid #000;
}

.styleguide {
  padding-block: 50px;
}
.styleguide hr {
  display: block;
}

.sg-subheading {
  border-block-end: 2px solid #666;
  margin-block: 60px 30px;
}

.sg-dark-bg {
  background-color: #898989;
}

.bg.black {
  background: var(--black);
}
.bg.white {
  background: var(--white);
}
.bg.error {
  background: var(--error);
}

.subheading {
  margin-block: 0;
  text-transform: uppercase;
}

.heading {
  margin-block-start: 0;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

@media (width <= 499px) {
  h2.heading {
    font-size: 1.75rem;
  }
}
@media (width >= 500px) and (width <= 749px) {
  h2.heading {
    font-size: 1.90625rem;
  }
}
@media (width >= 750px) and (width <= 1023px) {
  h2.heading {
    font-size: 2.0625rem;
  }
}
@media (width >= 1024px) and (width <= 1249px) {
  h2.heading {
    font-size: 2.21875rem;
  }
}
@media (width >= 1250px) {
  h2.heading {
    font-size: 2.375rem;
  }
}