.subheading {
  margin-block: 0;
  text-transform: uppercase;
}

.heading {
  margin-block-start: 0;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

h2.heading {
  @include responsive-font(28px, 38px);
}
