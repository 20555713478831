.swiper {
  .swiper-button {
    background-color: var(--md-primary-color);
    border-radius: 9999px;
    color: var(--white);
    height: 56px;
    width: 56px;

    &:after {
      font-size: 32px;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid var(--md-primary-color);
      outline-offset: 4px;
    }
  }

  .swiper-button-prev {
    inset-inline-start: auto;
    inset-inline-end: 74px;
  }

  .swiper-button-next {
    inset-inline-end: 6px;
  }

  .swiper-pagination {
    display: none !important;

    @media (width >= 768px) {
      display: block !important;
    }
  }

  .swiper-pagination-bullet {
    background-color: var(--lt-gray-2);
    border-radius: 0;
    height: 4px;
    opacity: 1;
    position: relative;
    transition-property: background-color;
    width: 24px;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid var(--md-primary-color);
      outline-offset: 4px;
    }

    &:after {
      content: "";
      height: 24px;
      inset: 50%;
      position: absolute;
      translate: -50% -50%;
      width: 24px;
    }

    &.swiper-pagination-bullet-active {
      background-color: var(--md-gray);
    }
  }
}
