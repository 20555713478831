.sg-colors {
  @extend %clearfix;

  li {
    display: block;
    float: left;
    margin: 10px;
  }
}

.sg-swatch {
  display: block;
  width: 100px;
  height: 100px;
  border: 1px solid #000;
}

.styleguide {
  padding-block: 50px;

  hr {
    display: block;

    @extend %block-margin;
    @extend %clearfix;
  }
}

.sg-subheading {
  border-block-end: 2px solid #666;
  margin-block: 60px 30px;
}

.sg-element {
  @extend %clearfix;
}

.sg-dark-bg {
  background-color: #898989;
}

.bg {
  &.black {
    background: var(--black);
  }

  &.white {
    background: var(--white);
  }

  &.error {
    background: var(--error);
  }
}
