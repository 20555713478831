.block-image-tiles-callout {
  // @extend %block-margin;

  display: grid;
  gap: var(--small-block-margin);

  @media (width >= 768px) {
    gap: 40px;
    grid-template-columns: 1fr minmax(0, 415px);
  }

  @media (width > 1024px) {
    gap: 100px;
  }

  &.image-position-right {
    @media (width >= 768px) {
      grid-template-columns: minmax(0, 415px) 1fr;
    }

    .media {
      @media (width >= 768px) {
        grid-column: 2;
        grid-row: 1;
      }
    }

    .content {
      @media (width >= 768px) {
        grid-column: 1;
        grid-row: 1;
      }
    }
  }

  .media {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    picture {
      height: 100%;
      width: 100%;
    }

    picture:first-of-type {
      grid-column: 1;
      grid-row: 1 / 3;
    }

    picture:nth-of-type(2) {
      grid-column: 2;
      grid-row: 1;
    }

    picture:nth-of-type(3) {
      grid-column: 2;
      grid-row: 2;
    }

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .btn {
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid var(--md-primary-color);
      outline-offset: 4px;
    }
  }
}
