.block-related-work {
  // @extend %block-margin;

  p {
    margin-block: 0;
    text-transform: uppercase;
  }

  .heading {
    margin-block: 0 32px;
  }

  .swiper-slide {
    padding-block-end: 90px;
  }

  .swiper-pagination {
    inset-inline-start: 50% !important;
    translate: -50% 0 !important;
    width: calc(100% - 280px) !important;
  }

  .swiper-button-prev {
    inset-inline-start: auto;
    inset-inline-end: 78px;
  }

  .swiper-button {
    inset-block-start: auto;
    inset-block-end: 6px;
    translate: 0;
  }

  figure {
    margin: 0;
  }

  .content {
    background-color: var(--white);
    box-shadow: 4.8px 4.8px 16px 0px rgba(0, 0, 0, 0.30);
    margin-inline: 22px;
    margin-block-start: -56px;
    padding: 30px 20px;
    position: relative;
    text-align: center;
    z-index: 1;

    p {
      margin-block-end: 0;
    }
    
    h3 {
      margin-block-start: 0;
    }

    .block-callout-link {
      @extend %ada-link;

      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2218%22%20viewBox%3D%220%200%2016%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.586304%208.59881H14%22%20stroke%3D%22%23EB0000%22%20stroke-width%3D%222.5%22%2F%3E%3Cpath%20d%3D%22M6.60425%201.4126C6.60425%201.4126%208.04443%202.81205%2010.9248%205.61096C11.8129%206.47399%2014%208.5992%2014%208.5992L6.60425%2016.2557%22%20stroke%3D%22%23EB0000%22%20stroke-width%3D%222.5%22%2F%3E%3C%2Fsvg%3E");
      background-repeat: no-repeat;
      background-position: 100% 50%;
      padding-inline-end: 24px;

      &:focus-visible {
        outline: 2px solid var(--md-primary-color);
        outline-offset: 4px;
      }
    }
  }
}
