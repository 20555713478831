@use "sass:math";

@mixin responsive-font($min, $max) {
  @if unit($min) == "px" {
    $max: $max / 16;
    $min: $min / 16;
  }
  @else {
    @warn "Make sure `$min and $max` are in px or rem.";
  }

  $font-difference: strip-unit($max) - strip-unit($min);
  $min: strip-unit($min);
  $max: strip-unit($max);

  @media (width <= 499px) {
    font-size: $min * 1rem;
  }

  @media (width >= 500px) and (width <= 749px) {
    font-size: (math.div($font-difference, 4) + $min) * 1rem;
  }

  @media (width >= 750px) and (width <= 1023px) {
    font-size: ($min + math.div($font-difference, 4) + math.div($font-difference, 4)) * 1rem;
  }

  @media (width >= 1024px) and (width <= 1249px) {
    font-size: ($max - math.div($font-difference, 4)) * 1rem;
  }

  @media (width >= 1250px) {
    font-size: $max * 1rem;
  }
}
