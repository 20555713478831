:root {
  /* Colors */
  --black: #000;
  --white: #fff;
  --md-primary-color: #EB0000;
  --md-secondary-color: #eb7e23;
  --lt-gray-2: #eee;
  --lt-gray: #F5F5F5;
  --md-gray: #777777;
  --dk-gray: #212529;
  --error: #ca1717;
  --max-width: 1380px;
  --max-width-large: 1500px;
  --large-container-padding: 40px;
  --small-container-padding: 20px;

  /* Margin top and bottom on block element items */
  --large-block-margin: 80px;
  --small-block-margin: 30px;

  /* Margin on position-right and left floated items */
  --large-position-margin: 35px;
}
