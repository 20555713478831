.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

.skip-link a,
button.skip-link {
  color: var(--white);
  background: var(--black);
  padding-block: 3px;
  padding-inline: 15px;
  width: auto;
  display: block;
  border-radius: 0 0 5px 5px;
  position: absolute;
  inset-block-start: 0;
  z-index: 4500;

  @include responsive-font(16px, 16px);

  font-weight: 400;
  text-transform: uppercase;
  inset-inline-start: -10000px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--white);
    inset-inline-start: 45px;
  }
}
