// .fui-form {
//   --fui-font: var(--font-family-sans-serif);
//   --fui-check-bg-color-checked: var(--md-primary-color);
//   --fui-error: var(--error);
//   --fui-label-error-color: var(--error);
//   --fui-label-error-border-color: var(--error);
//   --fui-input-error-color: var(--error);
//   --fui-input-error-border-color: var(--error);
//   --fui-submit-btn-bg-color: var(--md-primary-color);
//   --fui-submit-btn-bg-color-hover: var(--white);
//   --fui-submit-btn-border-color: var(--md-primary-color);
//   --fui-submit-btn-border-color-hover: var(--primary);
//   --fui-submit-btn-color-hover: var(--md-primary-color);
// }
// .fui-input,
// .fui-select,
// .fui-textarea {
//   &:focus {
//     box-shadow: 0!important;
//     border-color: var(--md-primary-color);
//     outline: 2px solid var(--md-primary-color)!important;
//     outline-offset: 2px!important;
//   }
// }
// .fui-checkbox input:focus + label::before,
// .fui-radio input:focus + label::before {
//   outline: 2px solid var(--md-primary-color);
//   outline-offset: 2px;
// }

// .fui-checkbox input:checked + label::before,
// .fui-radio input:checked + label::before {
//   border: 1px solid var(--md-primary-color);
// }

.row {
  display: block;
  justify-content: space-between;
  align-items: flex-end;
  margin-inline: -10px;

  @media (width >= 1024px) {
    display: flex;
  }
}

.column {
  flex: 1 1 auto;
  padding-block: 8px;
  margin-inline: 10px;
  box-sizing: border-box;

  @media (width >= 750px) {
    padding-block: 15px;
  }
}

.fui-i {
  --fui-label-font-size: 1rem;
  --fui-check-font-size: 1rem;
  --fui-input-font-size: 1rem;
  --fui-instructions-font-size: 1rem;
  --fui-error-font-size: 1rem;
  --fui-tabs-font-size: 1rem;
  --fui-btn-font-size: 1rem;
  --fui-progress-font-size: 1rem;
  --fui-table-th-font-size: 1rem;
  --fui-tabs-margin-bottom: 30px;
  --fui-progress-height: 30px;
  --fui-required-color: var(--md-primary-color);
  --fui-font: var(--font-family-sans-serif);
  --fui-color: var(--black);
  --fui-label-color: var(--black);
  --fui-primary-color: var(--md-primary-color);
  --fui-primary-color-hover: var(--md-primary-color);
  --fui-border-radius: 0.25rem;
  --fui-border-color: var(--black);
  --fui-focus-border-color: var(--black);
  --fui-focus-shadow: 0 0 0 3px rgba(0, 0, 0, #000);
  --fui-check-label-bg-color: var(--white);
  --fui-check-bg-color: var(--white);
  --fui-check-check-border-radius: 2px;
  --fui-check-label-width: 20px;
  --fui-check-label-height: 20px;
  --fui-check-bg-color-checked: var(--md-primary-color);
  --fui-input-background-color: var(--white);
  --fui-input-error-color: var(--error);
  --fui-input-error-border-color: var(--error);
  --fui-table-th-color: var(--white);
  --fui-progress-color: var(--white);
  --fui-tab-active-bg-color: var(--md-primary-color);
  --fui-font-size: 1rem;
  --fui-error: var(--error);
  --fui-success: var(--md-secondary-color);
  --fui-row-gutter: 30px;
  --fui-row-gutter-top: 20px;
  // --fui-field-gutter: 30px;
  --fui-loading-min-height: 20px;
  --fui-loading-height: 20px;
  --fui-loading-width: 20px;
  --fui-loading-margin-top: -10px;
  --fui-loading-margin-left: -10px;
  --fui-loading-border-width: 2px;
  --fui-alert-padding: 20px;
  --fui-alert-font-size: 1rem;
  --fui-alert-font-weight: 500;
  --fui-alert-margin-bottom: 30px;
  --fui-btn-font-size: 1rem;
  --fui-btn-padding: 10px 15px;
  --fui-title-margin: 0 0 20px;
  --fui-title-font-size: 2.4rem;
  --fui-title-font-weight: 700;
  --fui-page-title-margin: 0 0 20px;
  --fui-page-title-font-size: 2.2rem;
  --fui-page-title-font-weight: 600;
  --fui-tabs-margin-bottom: 20px;
  --fui-tabs-font-size: 1rem;
  --fui-tab-padding: 10px 20px;
  --fui-progress-font-size: 1rem;
  --fui-progress-bar-bg-color: var(--md-primary-color);
  --fui-error-font-size: 1rem;
  --fui-error-margin-top: 5px;
  --fui-label-line-height: 1.25;
  --fui-label-font-weight: 500;
  --fui-label-margin: 5px;
  --fui-label-error-color: var(--error);
  --fui-label-error-border-color: var(--error);
  --fui-instructions-font-size: 1rem;
  --fui-instructions-line-height: 1.5;
  --fui-instructions-margin: 8px;
  --fui-instructions-color: var(--black);
  --fui-check-font-size: 1rem;
  --fui-check-line-height: 1.5;
  --fui-check-margin-bottom: 3px;
  --fui-check-margin-right: 30px;
  --fui-check-label-padding-left: 30px;
  --fui-check-label-line-height: 2;
  --fui-check-label-top: 5px;
  --fui-check-check-bg-size: 8px auto;
  --fui-check-radio-border-radius: 50%;
  --fui-check-radio-bg-size: 8px auto;
  --fui-group-padding: 25px;
  --fui-input-font-size: 1rem;
  --fui-input-padding: 10px 20px;
  --fui-repeater-row-padding: 20px;
  --fui-repeater-row-margin-bottom: 20px;
  --fui-table-margin-bottom: 20px;
  --fui-table-row-padding: 15px;
  --fui-table-th-font-size: 1rem;
  --fui-signature-height: 100px;
  --fui-summary-padding: 20px;
  --fui-submit-btn-bg-color: var(--md-primary-color);
  --fui-submit-btn-bg-color-hover: var(--white);
  --fui-submit-btn-border-color: var(--md-primary-color);
  --fui-submit-btn-border-color-hover: var(--md-primary-color);
  --fui-submit-btn-color-hover: var(--md-primary-color);
}

.block-form {
  @extend %block-margin;
}

.fui-progress-end .fui-progress {
  @extend %block-margin;
}

.fui-table th:first-child,
.fui-table td:first-child {
  padding-inline-start: 15px;
}

.fui-row {
  flex-flow: column;

  @media (width >= 750px) {
    flex-flow: row;
  }
}

.fui-legend {
  font-size: 1rem;
}

.fui-label,
.fui-legend {
  @include responsive-font(15px, 15px);

  margin-block-end: 10px;
  display: block;
  font-weight: 700;
  color: var(--md-primary-color);
}

.fui-input,
.fui-select,
.fui-textarea {
  border: 1px solid var(--md-lt-gray);

  @include responsive-font(16px, 18px);

  line-height: 32px;
  padding-block: 8px;
  padding-inline: 15px;
  background: var(--white);
  appearance: none;
  border-radius: 15px;
  color: var(--black);
  width: 100%;

  &:focus {
    box-shadow: none;
    color: var(--black)!important;
    outline: 2px solid var(--md-primary-color)!important;
    outline-offset: 2px;
  }
}

.fui-select:not([multiple], [size]) {
  padding-right: 50px;
  background-position: right 10px center !important;
  background-size: 23px 9px;
  background-repeat: no-repeat;
  text-align: left;
  appearance: none;
  background-image: url("/assets/images/icons/select-arrow.svg") !important;
}

.fui-checkbox,
.fui-radio {
  font-weight: 400;
  color: var(--black);

  label {
    padding-left: 0;
    padding-inline-start: var(--fui-check-label-padding-left);

    &::before {
      inset-inline-start: 0;
    }
  }
}

.fui-checkbox input:focus + label::before,
.fui-radio input:focus + label::before {
  outline: 2px solid var(--md-primary-color);
  outline-offset: 2px;
}

.fui-checkbox input:checked + label::before,
.fui-radio input:checked + label::before {
  border: 1px solid var(--md-primary-color);
}

.fui-btn {
  &:focus-visible {
    outline-offset: 4px;
    outline: 2px solid var(--md-primary-color);
  }
}

.fui-tab {
  &.fui-tab-active {
    a {
      color: var(--white);
    }
  }
}

.fui-progress-bar,
.fui-progress {
  border-radius: 50px;
}


.block-form {
  h2 {
    margin-top: 0;
  }
  form {
    margin-top: 2rem;
  }
}