.block-accordion {
  // @extend %block-margin;

  box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.22);
  margin-block: 45px;
  padding: 0;

  & + .block-accordion {
    margin-block-start: -23px;
  }

  .accordion-toggle {
    display: block;
    width: 100%;
    margin-block-end: 0;

    button {
      background-color: var(--lt-gray);
      border: 0;
      color: var(--black);
      display: block;
      cursor: pointer;
      padding-block: 37px;
      padding-inline-start: 30px;
      padding-inline-end: 70px;
      position: relative;
      text-align: start;
      transition: color 0.2s, background-color 0.2s, border 0.2s;
      width: 100%;

      @include responsive-font(16px, 26px);

      @media (width >= 1024px) {
        padding-inline-start: 70px;
      }

      &::before {
        content: "";

        background-color: var(--md-primary-color);
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2037%2037%22%3E%3Cpath%20style%3D%22fill%3A%20%23fff%3B%22%20d%3D%22M18.5%2C30.18c-.83%2C0-1.5-.67-1.5-1.5V9.4c0-.83.67-1.5%2C1.5-1.5s1.5.67%2C1.5%2C1.5v19.28c0%2C.83-.67%2C1.5-1.5%2C1.5Z%22%2F%3E%3Cpath%20style%3D%22fill%3A%20%23fff%3B%22%20d%3D%22M29%2C20.54H8c-.83%2C0-1.5-.67-1.5-1.5s.67-1.5%2C1.5-1.5h21c.83%2C0%2C1.5.67%2C1.5%2C1.5s-.67%2C1.5-1.5%2C1.5Z%22%2F%3E%3C%2Fsvg%3E");
        border-radius: 9999px;
        display: block;
        width: 37px;
        height: 37px;
        text-align: center;
        position: absolute;
        inset-inline-end: 27px;
        inset-block-start: 50%;
        transform: translateY(-50%);
        transition: all 0.3s;
      }

      &[aria-expanded="true"] {
        color: var(--dk-gray);
        background: var(--white);
        border-block-end: 0;
      }

      &:hover,
      &:focus {
        background-color: var(--white);
        color: var(--dk-gray);

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2037%2037%22%3E%3Cpath%20style%3D%22fill%3A%20%23fff%3B%22%20d%3D%22M29%2C20.54H8c-.83%2C0-1.5-.67-1.5-1.5s.67-1.5%2C1.5-1.5h21c.83%2C0%2C1.5.67%2C1.5%2C1.5s-.67%2C1.5-1.5%2C1.5Z%22%2F%3E%3C%2Fsvg%3E");
        }
      }

      &:focus {
        outline: none;
      }
  
      &:focus-visible {
        outline: 2px solid var(--md-primary-color);
        outline-offset: 4px;
      }
    }
  }

  .accordion-content {
    background-color: var(--white);
    padding-inline: 30px;
    padding-block: 0 53px;

    @include responsive-font(14px, 16px);

    @media (width >= 1024px) {
      padding-inline: 70px;
    }

    *:first-child {
      margin-block-start: 0;
    }

    *:last-child {
      margin-block-end: 0;
    }
  }
}
