.block-rich-content-overlapping {
  // @extend %block-margin;

  display: grid;
  gap: 40px;

  @media (width >= 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.dark-bg {
    .content-inner {
      background-color: var(--md-primary-color);
    }

    .content-inner,
    a:not(.btn-primary) {
      color: var(--white);
    }

    .btn-primary {
      background-color: var(--white);
      color: var(--md-primary-color);

      &:hover {
        background-color: var(--lt-gray);
      }
    }

    li:before {
      color: var(--white);
    }
  }

  &.image-position-left {
    .media {
      @media (width > 768px) {
        grid-column: 1 / 3;
        grid-row: 1;
      }
    }

    .content {
      @media (width > 768px) {
        grid-column: 2 / 4;
        grid-row: 1;
      }
    }
  }

  .media {
    @media (width > 768px) {
      grid-column: 2 / 4;
      grid-row: 1;
    }
  }

  .content {
    @media (width > 768px) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      grid-column: 1 / 3;
      grid-row: 1;
      justify-content: center;
    }

    a:not(.btn-primary) {
      text-decoration: underline;
      text-transform: uppercase;
    }

    .btn-primary {
      margin-block-start: 40px;
    }

    ul,
    ol {
      @media (width > 768px) {
        columns: 2;
      }
    }
  }

  .content-inner {
    background-color: var(--lt-gray);
    box-shadow: 4.8px 4.8px 16px 0px rgba(0, 0, 0, 0.30);
    padding: 30px;

    @media (width > 768px) {
      padding: 65px 100px 50px;
    }
  }
}
