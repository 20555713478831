%primary-font {
  font-family: Arial, sans-serif;
}

%secondary-font {
  font-family: Arial, sans-serif;
}

%clearfix {
  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }

  zoom: 1;
}

%ada-link {
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
  }
}

%block-margin {
  margin-block: var(--small-block-margin);

  @media (width >= 750px) {
    margin-block: var(--large-block-margin);
  }
}

%position-right-margin {
  margin-inline: auto;

  @media (width >= 750px) {
    margin-inline: var(--large-block-margin) 0;
  }
}

%position-left-margin {
  margin-inline: auto;

  @media (width >= 750px) {
    margin-inline: 0 var(--large-block-margin);
  }
}

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
