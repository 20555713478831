.block-testimonial-gallery {
  // @extend %block-margin;

  &.swiper {
    .swiper-slide {
      padding-block-end: 80px;
  
      @media (width >= 768px) {
        padding-block: 6px 40px;
        padding-inline: 84px 120px;
      }
    }
  
    .swiper-button {
      inset-block-start: auto;
      inset-block-end: 6px;

      @media (width >= 768px) {
        inset-block-start: calc(50% - 20px);
        inset-block-end: auto;
      }
    }
  
    .swiper-button-prev {
      @media (width >= 768px) {
        inset-inline-start: 6px;
        inset-inline-end: auto;
      }
    }
  
    .swiper-slide-active + .swiper-slide-next {
      @media (width >= 768px) {
        margin-inline-start: -268px;
      }
    }
  }

  .testimonial-quote {
    box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.22);
    padding: 30px;

    @media (width >= 768px) {
      padding: 100px 70px;
    }

    p {
      @include responsive-font(16px, 26px);
    }

    cite {
      font-style: normal;
      text-transform: uppercase;

      @include responsive-font(12px, 14px);
    }
  }

  .swiper-slide-active {
    .testimonial-quote {
      background-color: var(--md-primary-color);
      color: var(--white);
    }
  }
}
